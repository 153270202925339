import { NgModule } from '@angular/core';
import {
    PathLocationStrategy,
    LocationStrategy,
    APP_BASE_HREF,
} from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import {
    GoogleLoginProvider,
    GoogleSigninButtonModule,
    SocialAuthServiceConfig,
    SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { AuthInterceptor } from './demo/components/auth/auth.interceptor';

// @NgModule({
//     declarations: [AppComponent, NotfoundComponent],
//     imports: [AppRoutingModule, AppLayoutModule],
//     providers: [
//         { provide: LocationStrategy, useClass: PathLocationStrategy },
//         CountryService,
//         CustomerService,
//         EventService,
//         IconService,
//         NodeService,
//         PhotoService,
//         ProductService,
//     ],
//     bootstrap: [AppComponent],
// })
// export class AppModule {}

@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        SocialLoginModule,
        BrowserModule,
        HttpClientModule,
        GoogleSigninButtonModule,
        ToastModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, // Register the interceptor

        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '1064234861469-93d7v20l7bc03kbfblel23feirr93mrj.apps.googleusercontent.com',
                            { oneTapEnabled: false, prompt: 'consent' }
                        ),
                    },
                ],
            } as unknown as SocialAuthServiceConfig,
        },
        {
            provide: MessageService,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

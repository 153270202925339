import { Injectable } from '@angular/core';
import { User } from '../model/userModel';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Response } from '../model/response';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { Router, RouterLink } from '@angular/router';
import { Observable, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    getToken() {
        throw new Error('Method not implemented.');
    }

    isLoggedIn(): boolean {
        return !!localStorage.getItem('token');
    }

    getUserRole(): string {
        return localStorage.getItem('role');
    }
    private token: string | null = null;
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient, private router: Router) {}

    validateToken(storedToken: string): Observable<any> {
        const url = `${this.apiUrl}/Auth/validateToken?tokens=${storedToken}`;
        return this.http.get<Response<User>>(url);
    }

    login(userName: string) {
        debugger;
        const credentials = { userName };
        return this.http.post<Response<User>>(
            this.apiUrl + '/Auth/login',
            credentials
        );
    }

    register(userName: string, passwordHash: string) {
        const credentials = { userName, passwordHash };
        return this.http.post<Response<User>>(
            this.apiUrl + '/Auth/register',
            credentials
        );
    }

    logout() {
        localStorage.removeItem('token');
        this.token = null;
        this.router.navigateByUrl('');
    }
    refreshToken() {
        return this.http.get<Response<User>>(this.apiUrl + 'Auth/refreshtoken');
    }
}

<div class="layout-footer">
    <!-- <img
        src="assets/layout/images/{{
            layoutService.config().colorScheme === 'light'
                ? 'logo-dark'
                : 'logo-white'
        }}.svg"
        alt="Logo"
        height="20"
        class="mr-2"
    />
    by
    <span class="font-medium ml-2">DMS FOOTER</span> -->
    <img src="assets/mondir.png" alt="Logo" height="30" class="mr-2" />
    by
    <span class="font-medium ml-6">DMS UI</span>
</div>
